import { SaveFeedbackDto } from '@ac/models';
import {Component, effect, inject, OnInit, signal} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BrowserInfoService } from '../browser-info.service';
import { IdentityService } from '../identity/identity.service';
import { FeedbackApiService } from './feedback-api.service';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import moment from 'moment';
import { ContactWindowState } from './contact-window-state.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSelectChange } from '@angular/material/select';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  feedbackForm: UntypedFormGroup;
  #state = inject(ContactWindowState);
  #transloco = inject(TranslocoService);
  subjects$: Observable<string[]> = this.#transloco
    .selectTranslate('subscription.awareness-page-redirect-dialog.package-upgrade-form-subject')
    .pipe(
      map(packageUpgradeSubject => [
        'Fehler melden',
        'Frage stellen',
        'Rechtliche Frage stellen',
        'Idee vorschlagen',
        'Feedback geben',
        packageUpgradeSubject
      ])
    );
  protected isOpen = this.#state.isOpen;
  showDisclaimer = signal(false);

  constructor(
    private browserInfo: BrowserInfoService,
    public identityService: IdentityService,
    private formBuilder: UntypedFormBuilder,
    private feedbackApi: FeedbackApiService
  ) {
    this.feedbackForm = this.formBuilder.group({
      subject: ['', [Validators.required]],
      message: ['', [Validators.required, Validators.maxLength(1000)]]
    });

    this.feedbackForm.valueChanges
      .pipe(
        takeUntilDestroyed(),
        distinctUntilChanged(),
        tap(values => this.#state.setForm(values))
      )
      .subscribe();

    effect(
      () => {
        const formValues = this.#state.formValues();

        this.feedbackForm.patchValue(formValues, { emitEvent: false });
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    this.browserInfo.getBrowserInfo();
  }

  toggleMenu() {
    if (this.#state.isOpen()) this.#state.close();
    else this.#state.open();
  }

  onSubmit(feedbackForm: UntypedFormGroup, event: Event) {
    event.preventDefault();

    if (feedbackForm.invalid) {
      return;
    }

    const browserInfo = this.browserInfo.getBrowserInfo();

    const dto: SaveFeedbackDto = {
      browserLanguage: browserInfo.browserLanguage,
      browserName: browserInfo.browserName,
      browserVersion: browserInfo.browserVersion,
      currentPath: window.location.href,
      dateTime: moment().toISOString(true),
      nativeScreenHeight: Math.round(browserInfo.nativeScreenHeight),
      nativeScreenWidth: Math.round(browserInfo.nativeScreenWidth),
      operatingSystem: browserInfo.operatingSystem.toString(),
      screenHeight: Math.round(browserInfo.screenHeight),
      screenWidth: Math.round(browserInfo.screenWidth),
      message: feedbackForm.get('message').value,
      subject: feedbackForm.get('subject').value
    };

    this.feedbackApi
      .sendFeedback(dto)
      .pipe(
        tap(() => {
          this.feedbackForm.reset();
          //After resetting all required errors are present: https://github.com/angular/components/issues/4190
          Object.keys(this.feedbackForm.controls).forEach(key => {
            this.feedbackForm.controls[key].setErrors(null);
          });
          this.toggleMenu();
        })
      )
      .subscribe();
  }

  subjectChanged($event: MatSelectChange) {
    const subject = $event.value;

    const packageUpgradeSubject = this.#transloco.translate(
      'subscription.awareness-page-redirect-dialog.package-upgrade-form-subject'
    );

    this.showDisclaimer.set(false);

    if (subject === packageUpgradeSubject) {
      this.#state.setForm({
        subject: this.#transloco.translate('subscription.awareness-page-redirect-dialog.package-upgrade-form-subject'),
        message: this.#transloco.translate('subscription.awareness-page-redirect-dialog.package-upgrade-form-message')
      });
    }else if(subject === "Rechtliche Frage stellen"){
      this.showDisclaimer.set(true);
      this.#state.setForm({subject: 'Rechtliche Frage stellen', message: 'Sehr geehrte Damen und Herren,\n\nich habe eine rechtliche Frage zum Thema: <THEMA>\n\n<FRAGE>\n\nSie können mich per E-Mail oder folgender Telefonnummer erreichen:\n<TELEFON>'})
    }
  }
}
