import { Component, input, Input } from '@angular/core';
import { hexToRGB } from '@app/utils/hex-to-rgb';

@Component({
  selector: 'awareness-center-percentage-animation',
  templateUrl: './percentage-animation.component.html',
  styleUrls: ['./percentage-animation.component.scss']
})
export class PercentageAnimationComponent {
  @Input() percentage = 0;
  @Input() showCircle = true;
  animation = input(true);

  innerStrokeColor = hexToRGB(getComputedStyle(document.documentElement).getPropertyValue('--on-surface'), 0.2);
}
