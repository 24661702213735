import { Directive, effect, ElementRef, HostListener, inject, input } from '@angular/core';
import { ContactWindowState } from '@app/feedback';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationService } from '@ac/colibri';
import { environment } from '@env';

@Directive({
  standalone: true,
  selector: '[needsPackageUpgrade]'
})
export class NeedsPackageUpgradeDirective {
  needsPackageUpgrade = input.required<boolean>();
  #contactWindow = inject(ContactWindowState);
  #transloco = inject(TranslocoService);
  #confirmationService = inject(ConfirmationService);
  #el = inject(ElementRef);

  constructor() {
    effect(() => {
      if (this.needsPackageUpgrade()) {
        this.#el.nativeElement.style.cursor = 'pointer';
      } else {
        this.#el.nativeElement.style.removeProperty('cursor');
      }
    });
  }

  @HostListener('click', ['$event'])
  async onClick() {
    if (!this.needsPackageUpgrade()) {
      return;
    }

    const confirmed = await this.#confirmationService.confirm({
      title: this.#transloco.translate('subscription.packageUpgradeConfirmationDialog.title'),
      message: this.#transloco.translate('subscription.packageUpgradeConfirmationDialog.message', {
        url: environment.urls.packages
      }),
      confirmLabel: this.#transloco.translate('subscription.packageUpgradeConfirmationDialog.confirmLabel'),
      cancelLabel: this.#transloco.translate('subscription.packageUpgradeConfirmationDialog.cancelLabel')
    });

    if (confirmed) {
      this.#contactWindow.setForm({
        subject: this.#transloco.translate('subscription.awareness-page-redirect-dialog.package-upgrade-form-subject'),
        message: this.#transloco.translate('subscription.awareness-page-redirect-dialog.package-upgrade-form-message')
      });
      this.#contactWindow.open();
    }
  }
}
